import React, { useState, useEffect } from "react";

import Dealer from "views/Dealers/Tabs/Dealer";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import Notification from "components/Notification/Notification.js";
import SelectDealer from "components/SelectDealer/SelectDealer";
import GoLiveButton from "components/GoLiveButton/GoLiveButton";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";

import useDealer from "hooks/useDealer";
import useUser from "hooks/useUser";

import { CREATE, EDIT } from "const";
import { Box } from "@material-ui/core";

export default function DealerProfile() {
    const [user] = useUser();
    const dealer = useDealer();
    const [form, setForm] = useState(EDIT);
    const [notification, setNotification] = useState({
        open: false,
        color: "success",
    });
    const [initialValues, setInitialValues] = useState({});

    useEffect(() => {
        setInitialValues(dealer);
    }, [dealer]);

    return (
        <>
            {notification.open && (
                <Notification
                    open={notification.open}
                    color={notification.color}
                    message={notification.message}
                />
            )}

            <GridContainer>
                <GridItem xs={12}>
                    <SelectDealer />
                </GridItem>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="primary" icon>
                            <CardIcon color="primary">
                                <Assignment />
                            </CardIcon>
                            {user?.isAdmin === 1 && (
                                <Box style={{ display: "flex" }}>
                                    <Button
                                        color="primary"
                                        onClick={() => {
                                            setForm(CREATE);
                                            setInitialValues({});
                                        }}
                                    >
                                        Create dealer
                                    </Button>
                                    <GoLiveButton
                                        id={initialValues?.id}
                                        dealerDomain={initialValues?.dealerDomain}
                                        isPublished={initialValues?.isPublished}
                                    />
                                </Box>
                            )}
                        </CardHeader>
                        <CardBody>
                            <Dealer dlid={dealer.id} initialValues={initialValues} form={form} />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </>
    );
}
