import React from "react";
import useSWR from "swr";
import { Box, TextField, MenuItem, Checkbox, CircularProgress } from "@material-ui/core";

// Función para hacer fetch de la API
const fetcher = (url) => fetch(url).then((res) => res.json());

const CountrySelect = ({ selectedCountries = [], onChange = () => {} }) => {
    const { data: countries, error } = useSWR("https://restcountries.com/v3.1/all", fetcher);

    if (error) return <div>Error loading countries</div>;
    if (!countries) return <CircularProgress />;

    // Ordenar países alfabéticamente por nombre común
    const sortedCountries = countries.sort((a, b) => a.name.common.localeCompare(b.name.common));

    // Función para manejar el cambio de selección
    const handleCountryChange = (countryCode) => {
        const isAlreadySelected = selectedCountries.includes(countryCode);

        let updatedCountries;
        if (isAlreadySelected) {
            updatedCountries = selectedCountries.filter((country) => country !== countryCode);
        } else {
            updatedCountries = [...selectedCountries, countryCode];
        }

        onChange(updatedCountries.filter((c) => c));
    };

    return (
        <Box flex={1}>
            <label style={{ fontSize: 12, color: "#9b9b9b" }}>Select Countries</label>
            <TextField
                select
                fullWidth
                value={selectedCountries}
                SelectProps={{
                    multiple: true,
                    renderValue: (selected) => selected.join(", "),
                }}
            >
                {sortedCountries.map((country) => (
                    <MenuItem
                        key={country.cca2}
                        value={country.cca2} // Usamos `cca2` como valor
                        onClick={() => handleCountryChange(country.cca2)} // Pasamos `cca2` al manejador
                    >
                        <Checkbox checked={selectedCountries.includes(country.cca2)} />
                        {country.name.common} {/* Muestra el nombre común del país */}
                    </MenuItem>
                ))}
            </TextField>
        </Box>
    );
};

export default CountrySelect;
