import { useState, useEffect } from "react";
import useCustomer from "hooks/useCustomer";

const paymentGatewayIds = {
    PAYPAL_PRO: 2,
    AUTHORIZE_NET: 8,
    PAYPAL_EXPRESS: 1,
};

export default function usePaymentGateway(customerId, pgatewayId) {
    const [resolvedGatewayId, setResolvedGatewayId] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const { customerProfile, error: customerError } = useCustomer(customerId);

    useEffect(() => {
        const determineGatewayId = () => {
            if (pgatewayId) {
                if (pgatewayId === paymentGatewayIds.PAYPAL_EXPRESS) {
                    setErrorMessage(
                        "The previous payment was processed using PayPal Express, which does not support additional charges. Please use a credit card to complete the transaction."
                    );
                    return;
                }

                if (
                    pgatewayId === paymentGatewayIds.PAYPAL_PRO &&
                    !customerProfile?.payflowProPnref
                ) {
                    setErrorMessage(
                        "PayPal Pro information is required to process this payment. Please update the payment method."
                    );
                    return;
                }

                if (
                    pgatewayId === paymentGatewayIds.AUTHORIZE_NET &&
                    !(
                        customerProfile?.authorizeNetCustomerProfileId ||
                        customerProfile?.authorizeNetPaymentProfileId
                    )
                ) {
                    setErrorMessage(
                        "Authorize.Net information is required to process this payment. Please update the payment method."
                    );
                    return;
                }

                setResolvedGatewayId(pgatewayId);
                return;
            }

            if (customerError) {
                setErrorMessage("Error loading customer profile.");
                return;
            }

            if (!customerProfile) {
                setErrorMessage("Customer profile not found.");
                return;
            }

            const hasAuthorizeNetInfo =
                customerProfile?.authorizeNetCustomerProfileId ||
                customerProfile?.authorizeNetPaymentProfileId;

            const hasPayPalProInfo = customerProfile?.payflowProPnref;

            if (hasAuthorizeNetInfo) {
                setResolvedGatewayId(paymentGatewayIds.AUTHORIZE_NET);
            } else if (hasPayPalProInfo) {
                setResolvedGatewayId(paymentGatewayIds.PAYPAL_PRO);
            } else {
                setErrorMessage(
                    "No prior authorization found for a charge. Please proceed with entering the card details to complete the transaction."
                );
                setResolvedGatewayId(null);
            }
        };

        determineGatewayId();
    }, [customerProfile, pgatewayId, customerError]);

    return { resolvedGatewayId, errorMessage };
}
