import React, { useState, useEffect } from "react";
import Card from "react-credit-card-component";
import "react-credit-card-component/dist/styles-compiled.css";
import ActionAlerts, { AlertSeverity } from "components/ActionAlerts/ActionAlerts";
import TextMask from "react-text-mask";
import { useFormik } from "formik";
import * as yup from "yup";
import { Box, Button, Grid, TextField } from "@material-ui/core";
import useCreditCardMasks from "hooks/useCreditCardMasks";
import { financial } from "helpers";
import usePaymentHandler from "hooks/usePaymentHandler";

const validationSchema = yup.object({
    cardNumber: yup
        .string()
        .test("is-card-number", "Card number must have 16 digits", (value) => {
            const cardNumber = (value || "").replace(/\s/g, "");
            return cardNumber.length === 16;
        })
        .required(),
    name: yup.string().required(),
    validThru: yup
        .string()
        .required()
        .matches(/^(0[1-9]|1[0-2])\/([0-9]{2})$/, "Valid thru must be in MM/YY format"),
    cvc: yup
        .string()
        .required()
        .matches(/^[0-9]{3,4}$/, "CVC must have 3 or 4 digits"),
});

const TRANSACTION_OK = "0";

export default function CreditCardForm({
    amountToBeCharged = 0,
    showConfirmation = false,
    paymentProcessor = "",
    billingInfo = {},
    onSuccess = () => {},
}) {
    const [disabled, setDisabled] = useState(false);
    const [error, setError] = useState("");
    const { cardNumberMask, cardValidThruMask, cardCvcMask } = useCreditCardMasks();
    const { loading, postPaypalPro, postAuthorizeNet } = usePaymentHandler();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            focused: "number",
            cardNumber: "",
            name: "",
            validThru: "",
            cvc: "",
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            if (showConfirmation) {
                const confirmMessage = `Do you want to proceed with the charge of $${financial(
                    amountToBeCharged
                )}?`;

                const confirmCharge = window.confirm(confirmMessage);

                if (!confirmCharge) {
                    return;
                }
            }

            switch (paymentProcessor) {
                case "PayPalPro":
                    const resPayPalPro = await postPaypalPro(
                        {
                            ...values,
                            ...billingInfo,
                            transactionAmount: amountToBeCharged,
                        },
                        onSuccess
                    );

                    if (resPayPalPro.RESULT !== TRANSACTION_OK) {
                        return setError(
                            "Transaction Failed: Your payment could not be processed. Please check your card details or try a different payment method. If the issue persists, contact customer support."
                        );
                    }

                    break;
                case "AuthorizeNet":
                    const resAuthorizeNet = postAuthorizeNet({
                        ...values,
                        ...billingInfo,
                        transactionAmount: amountToBeCharged,
                    });
                    break;
                default:
                    console.log("No payment processor matched.");
                    break;
            }
        },
    });

    useEffect(() => {
        return () => {
            setError("");
        };
    }, []);

    return (
        <form onSubmit={formik.handleSubmit}>
            <Box>
                <Card
                    cvc={formik.values.cvc}
                    expiry={formik.values.validThru}
                    focused={formik.values.focused}
                    name={formik.values.name}
                    number={formik.values.cardNumber}
                />
                <Box mt={2}>
                    <Grid container spacing={2}>
                        {error.length > 0 ? (
                            <Grid item xs={12} md={12} lg={12} xl={12}>
                                <ActionAlerts
                                    message={error}
                                    severity={AlertSeverity.ERROR}
                                    autoCloseTime={null}
                                    onClose={() => {}}
                                />
                            </Grid>
                        ) : null}
                        <Grid item xs={6} md={6} lg={6} xl={6}>
                            <TextField
                                fullWidth
                                label="Card Number"
                                id="cardNumber"
                                name="cardNumber"
                                value={formik.values.cardNumber}
                                onChange={formik.handleChange}
                                onFocus={() => formik.setFieldValue("focused", "number")}
                                error={
                                    formik.touched.cardNumber && Boolean(formik.errors.cardNumber)
                                }
                                InputProps={{
                                    inputComponent: TextMask,
                                    inputProps: {
                                        mask: cardNumberMask,
                                        guide: false,
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} md={6} lg={6} xl={6}>
                            <TextField
                                fullWidth
                                label="Name"
                                id="name"
                                name="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onFocus={() => formik.setFieldValue("focused", "name")}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                            />
                        </Grid>
                        <Grid item xs={6} md={6} lg={6} xl={6}>
                            <TextField
                                fullWidth
                                label="Valid Thru"
                                id="validThru"
                                name="validThru"
                                value={formik.values.validThru}
                                onChange={formik.handleChange}
                                onFocus={() => formik.setFieldValue("focused", "expiry")}
                                error={formik.touched.validThru && Boolean(formik.errors.validThru)}
                                InputProps={{
                                    inputComponent: TextMask,
                                    inputProps: {
                                        mask: cardValidThruMask,
                                        guide: false,
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} md={6} lg={6} xl={6}>
                            <TextField
                                fullWidth
                                label="CVC"
                                id="cvc"
                                name="cvc"
                                value={formik.values.cvc}
                                onChange={formik.handleChange}
                                onFocus={() => formik.setFieldValue("focused", "cvc")}
                                error={formik.touched.cvc && Boolean(formik.errors.cvc)}
                                InputProps={{
                                    inputComponent: TextMask,
                                    inputProps: {
                                        mask: cardCvcMask,
                                        guide: false,
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Box style={{ marginTop: 20, textAlign: "center" }}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={loading}
                            onClick={formik.handleSubmit}
                        >
                            {loading ? "Processing charge..." : "Confirm Charge"}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </form>
    );
}
