import useSWR from "swr";
import DealerCouponsService from "services/dealerCouponsService";
import { useSnackbar } from "./useSnackbar";

/**
 * Custom hook to manage dealer coupons
 * @param {Object} params Optional query parameters for fetching coupons
 */
export default function useDealerCoupons(dealerId, params = {}) {
    const openSnackbar = useSnackbar();

    const fetcher = async () => {
        const response = await DealerCouponsService.getAll(dealerId, params);
        return response.data.data || [];
    };

    const { data: coupons, error, mutate } = useSWR(["/dealer-coupons", params], fetcher);

    /**
     * Deletes a coupon by ID and updates the cache with mutate
     * @param {number} id Coupon ID to delete
     */
    const handleDeleteCoupon = async (id) => {
        try {
            await DealerCouponsService.del(id);
            mutate(
                coupons.filter((coupon) => coupon.id !== id),
                false
            );
            openSnackbar("success", "Coupon deleted successfully");
        } catch (err) {
            console.error("Error deleting coupon:", err);
            openSnackbar("error", "Failed to delete coupon");
        }
    };

    return {
        coupons,
        loading: !error && !coupons,
        error,
        handleDeleteCoupon,
        fetchCoupons: mutate,
    };
}
