import React, { useRef, forwardRef, useState, useEffect } from "react";
import ReactToPrint from "react-to-print";
import Moment from "react-moment";
import Button from "components/CustomButtons/Button.js";
import VisibilityIcon from "@material-ui/icons/Visibility";
import PrintIcon from "@material-ui/icons/Print";

import OrderDataService from "services/OrderService";

const financial = (number, region = "USA", isSaleTax = false) => {
    const minimumFractionDigits = isSaleTax && region === "CAN" ? 3 : 2;

    return Number.parseFloat(number).toFixed(minimumFractionDigits);
};
const getTranslation = (text, locale) => text;
const cellStyle = {
    borderBottom: "1px solid rgb(221, 221, 221)",
    padding: 5,
};

const ComponentToPrint = forwardRef((props, ref) => {
    const {
        orderNumber,
        createdAt,
        requiredDate,
        salesman,
        billCompany,
        shipCompany,
        payTerms,
        products = [],
        dealerCompany,
        dealerAddress1,
        dealerCity,
        dealerState,
        dealerZip,
    } = props.order;
    const locale = props.locale;

    return (
        <div ref={ref} style={{ margin: 20 }}>
            {!props.order?.pgatewayId ? (
                <div style={{ marginBottom: 10 }}>
                    {getTranslation(
                        "We are pleased to inform you that we have received your parts request. Our #1 priority is locating your requested items as quickly as possible. Once we are ready to process your order we will contact you to arrange payment and discuss shipping options.",
                        locale
                    )}
                </div>
            ) : null}
            <div>
                <table style={{ width: "100%" }} border="0">
                    <tbody>
                        <tr>
                            <td>
                                {getTranslation("Order number", locale)}: {dealerCompany}
                            </td>
                            <td style={{ textAlign: "right" }}>
                                {getTranslation("Order number", locale)}: {orderNumber}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {getTranslation("Address", locale)}: {dealerAddress1}
                                {dealerCity && `, ${dealerCity}`}
                                {dealerState && `, ${dealerState}`}
                                {dealerZip && ` ${dealerZip}`}
                            </td>
                            <td style={{ textAlign: "right" }}>
                                {getTranslation("Creation date", locale)}:{" "}
                                <Moment locale={locale} format="MM/DD/YYYY">
                                    {createdAt}
                                </Moment>
                            </td>
                        </tr>
                        <tr style={{ display: "none" }}>
                            <td style={{ textAlign: "right" }}>
                                {getTranslation("Required at", locale)}:{" "}
                                <Moment locale={locale} format="MM/DD/YYYY">
                                    {requiredDate}
                                </Moment>
                            </td>
                        </tr>
                        <tr style={{ display: "none" }}>
                            <td style={{ textAlign: "right" }}>
                                {getTranslation("Salesman", locale)}: {salesman}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div style={{ marginTop: 50 }}>
                <table style={{ width: "100%" }} border="0">
                    <tbody>
                        <tr>
                            <td width="50%">
                                {getTranslation("Bill to", locale)}: {billCompany}
                            </td>
                            <td style={{ textAlign: "right" }}>
                                {getTranslation("Ship to", locale)}: {shipCompany}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {getTranslation("Address line 1", locale)}:{" "}
                                {props.order.billAddress1}
                            </td>
                            <td style={{ textAlign: "right" }}>
                                {getTranslation("Address line 1", locale)}:{" "}
                                {props.order.shipAddress1}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {getTranslation("Address line 2", locale)}:{" "}
                                {props.order.billAddress2}
                            </td>
                            <td style={{ textAlign: "right" }}>
                                {getTranslation("Address line 2", locale)}:{" "}
                                {props.order.shipAddress2}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {getTranslation("State", locale)}: {props.order.billState}
                            </td>
                            <td style={{ textAlign: "right" }}>
                                {getTranslation("State", locale)}: {props.order.shipState}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {getTranslation("City", locale)}: {props.order.billCity}
                            </td>
                            <td style={{ textAlign: "right" }}>
                                {getTranslation("City", locale)}: {props.order.shipCity}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {getTranslation("ZIP", locale)}: {props.order.billZip}
                            </td>
                            <td style={{ textAlign: "right" }}>
                                {getTranslation("ZIP", locale)}: {props.order.shipZip}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {getTranslation("Country", locale)}: {props.order.billCountry}
                            </td>
                            <td style={{ textAlign: "right" }}>
                                {getTranslation("Country", locale)}: {props.order.shipCountry}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {getTranslation("Phone", locale)}: {props.order.billPhone}
                            </td>
                            <td style={{ textAlign: "right" }}>
                                {getTranslation("Phone", locale)}: {props.order.shipPhone}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {getTranslation("Email", locale)}: {props.order.billEmail}
                            </td>
                            <td style={{ textAlign: "right" }}>
                                {getTranslation("Email", locale)}: {props.order.shipEmail}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div style={{ marginTop: 50 }}>
                <table style={{ width: "100%", borderCollapse: "collapse" }} border="0">
                    <thead>
                        <tr>
                            <th width="50%" style={cellStyle}>
                                {getTranslation("Instructions", locale)}
                            </th>
                            <th style={cellStyle}>{getTranslation("Terms", locale)}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={cellStyle}>{props.order.instructions}</td>
                            <td style={{ ...cellStyle, textAlign: "right" }}>{payTerms}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div style={{ marginTop: 50 }}>
                <table style={{ width: "100%", borderCollapse: "collapse" }} border="0">
                    <thead>
                        <tr>
                            <th style={cellStyle}>Part number(s)</th>
                            <th style={cellStyle}>{getTranslation("Description", locale)}</th>
                            <th style={cellStyle}>{getTranslation("Price", locale)}</th>
                            <th style={cellStyle}>{getTranslation("Quantity", locale)}</th>
                            <th style={cellStyle}>Subtotal</th>
                        </tr>
                    </thead>
                    <tbody>
                        {products.map((p, i) => (
                            <tr key={i}>
                                <td style={{ ...cellStyle, textAlign: "center" }}>
                                    {p.partNumber}
                                </td>
                                <td style={cellStyle}>
                                    {p.description}
                                    <br />
                                    {p.year} {p.makeSlug} {p.modelSlug}
                                </td>
                                <td style={{ ...cellStyle, textAlign: "right" }}>
                                    ${financial(p.price)}
                                </td>
                                <td style={{ ...cellStyle, textAlign: "center" }}>{p.qty}</td>
                                <td style={{ ...cellStyle, textAlign: "right" }}>
                                    ${financial(p.price * p.qty)}
                                </td>
                            </tr>
                        ))}
                        <tr>
                            <td colSpan={3}></td>
                            <td style={{ padding: 5, textAlign: "right" }}>Subtotal</td>
                            <td style={{ padding: 5, textAlign: "right" }}>
                                ${financial(props.order.subtotal)}
                            </td>
                        </tr>
                        {props.order.shippingAmount > 0 && (
                            <tr>
                                <td colSpan={3}></td>
                                <td style={{ padding: 5, textAlign: "right" }}>Shipping</td>
                                <td style={{ padding: 5, textAlign: "right" }}>
                                    ${financial(props.order.shippingAmount)}
                                </td>
                            </tr>
                        )}
                        {props.order.fixedShipping > 0 && (
                            <tr>
                                <td colSpan={3}></td>
                                <td style={{ padding: 5, textAlign: "right" }}>Fixed Shipping</td>
                                <td style={{ padding: 5, textAlign: "right" }}>
                                    ${financial(props.order.fixedShipping)}
                                </td>
                            </tr>
                        )}
                        <tr>
                            <td colSpan={3}></td>
                            <td style={{ padding: 5, textAlign: "right" }}>
                                {getTranslation("Sales tax", locale)} ({props.order.salesTaxRate}%)
                                {props.order?.salesTaxExceptionNo?.length > 0 &&
                                    props.order?.staxEndDate?.length > 0 &&
                                    props.order?.salesTaxAmount === 0 && (
                                        <>
                                            <p style={{ marginTop: 0, marginBottom: 0 }}>
                                                {getTranslation(
                                                    "Sales tax exception number",
                                                    locale
                                                )}
                                                : {props.order.salesTaxExceptionNo}
                                            </p>
                                            <p style={{ marginTop: 0, marginBottom: 0 }}>
                                                {getTranslation("Tax end date", locale)}:{" "}
                                                {props.order.staxEndDate}
                                            </p>
                                        </>
                                    )}
                            </td>
                            <td style={{ padding: 5, textAlign: "right" }}>
                                $
                                {financial(
                                    props.order.salesTaxAmount,
                                    props.order.billCountry,
                                    true
                                )}
                            </td>
                        </tr>
                        {props.order.handlingFeeAmount > 0 && (
                            <tr>
                                <td colSpan={3}></td>
                                <td style={{ padding: 5, textAlign: "right" }}>Handling Fee</td>
                                <td style={{ padding: 5, textAlign: "right" }}>
                                    ${financial(props.order.handlingFeeAmount)}
                                </td>
                            </tr>
                        )}
                        {props.order.customFeeAmount > 0 && (
                            <tr>
                                <td colSpan={3}></td>
                                <td style={{ padding: 5, textAlign: "right" }}>
                                    {props.order.customFeeTitle}
                                </td>
                                <td style={{ padding: 5, textAlign: "right" }}>
                                    ${financial(props.order.customFeeAmount)}
                                </td>
                            </tr>
                        )}
                        <tr>
                            <td colSpan={3}></td>
                            <td style={{ padding: 5, textAlign: "right" }}>Total</td>
                            <td style={{ padding: 5, textAlign: "right" }}>
                                ${financial(props.order.orderAmount)}
                            </td>
                        </tr>
                    </tbody>
                </table>
                {props.order.userMemo && (
                    <div style={{ marginTop: 50 }}>
                        {getTranslation("User memo", locale)}: {props.order.userMemo}
                    </div>
                )}
                {props.order.shipMemo && (
                    <div style={{ marginTop: 50 }}>
                        {getTranslation("Shipping memo", locale)}: {props.order.shipMemo}
                    </div>
                )}
            </div>
        </div>
    );
});

ComponentToPrint.displayName = "ComponentToPrint";

export default function PrintOrder({ dealerId, id }) {
    const componentRef = useRef();
    const onBeforeGetContentResolve = React.useRef(null);
    const [order, setOrder] = useState({ orderNumber: "" });

    const handleOnBeforeGetContent = React.useCallback(() => {
        return new Promise((resolve) => {
            onBeforeGetContentResolve.current = resolve;
            OrderDataService.get(id).then((o) => {
                setOrder({
                    ...o,
                    products: o.details,
                });
                resolve();
            });
        });
    }, [setOrder]);

    return (
        <>
            <ReactToPrint
                trigger={() => {
                    return (
                        <Button
                            justIcon
                            round
                            simple
                            color="warning"
                            className="edit"
                            title="View order"
                        >
                            <PrintIcon />
                            {/*<VisibilityIcon />*/}
                        </Button>
                    );
                }}
                content={() => componentRef.current}
                documentTitle={`Order number ${order.orderNumber}`}
                removeAfterPrint
                onBeforeGetContent={handleOnBeforeGetContent}
            />
            <div style={{ display: "none" }}>
                <ComponentToPrint order={order} locale={"en"} ref={componentRef} />
            </div>
        </>
    );
}
