import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Table from "components/ReactTable/ReactTableV2";
import TextFieldRender from "components/TextFieldRender/TextFieldRender";
import TextFieldTableCell from "components/TextFieldTableCell/TextFieldTableCell";
import { useConfirmationDialog } from "hooks/useConfirmationDialog";
import useSendQuoteEmail from "hooks/useSendQuoteEmail";
import QuoteService from "services/QuoteService";

// libs
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import { Box, Typography } from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from "yup";
import PartSelectionDialog from "views/HomePageSpecials/PartSelectionDialog";

// Esquema de validación Yup
const validationSchema = yup.object({
    quoteRef: yup.string().required("Quote Ref is required"),
    labelManager: yup.string(),
    description: yup.string(),
    customerName: yup.string().required("Customer Name is required"),
    customerEmail: yup.string().email("Invalid email").required("Customer Email is required"),
    rows: yup
        .array()
        .of(
            yup.object().shape({
                partNumber: yup.string(),
                itemName: yup.string(),
                price: yup.number().typeError("Must be a number").nullable(),
                install: yup.number().typeError("Must be a number").nullable(),
                qty: yup.number().integer().typeError("Must be an integer").nullable(),
                // Guardamos `product` para referencia, pero no lo mostramos directamente
                product: yup.object().nullable(),
            })
        )
        .test(
            "at-least-one-filled",
            "Please add at least one product to your quote before submitting",
            (rows) =>
                rows.some(
                    (row) => row.partNumber || row.itemName || row.price || row.install || row.qty
                )
        ),
});

export default function EditQuoteV2({ dlid, form, quote, onSuccess = () => {} }) {
    const { openDialog, closeDialog } = useConfirmationDialog();
    const { sendQuoteEmail } = useSendQuoteEmail();

    const productDetails = quote?.details?.map((detail) => ({
        partNumber: detail.partNumber,
        itemName: detail.description,
        price: detail.price,
        qty: detail.qty,
        product: detail.productData,
    }));

    const formik = useFormik({
        initialValues: {
            id: quote.id || null,
            dealerId: dlid,
            quoteRef: quote.quoteRef || "",
            labelManager: quote.assignLabelManager || "",
            description: quote.description || "",
            customerName: quote.billCompany || "",
            customerEmail: quote.billEmail || "",
            rows:
                productDetails ||
                Array.from({ length: 5 }, () => ({
                    partNumber: "",
                    itemName: "",
                    price: "",
                    install: "",
                    qty: "",
                    product: null,
                })),
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const valuesParsed = {
                ...values,
                rows: values.rows.map((row) => {
                    if (row.product && row.product.slugs) {
                        const [
                            make,
                            year,
                            model,
                            trim,
                            engine,
                            category,
                            subcategory,
                        ] = row.product.slugs;
                        const qty = row.qty;
                        const subtotal = row.qty * row.price.price || row.price;

                        return {
                            ...row,
                            product: {
                                ...row.product,
                                quantity: qty,
                                subtotal,
                                catalog_id: row?.product?.catalog?.id,
                                isTaxable: 1,
                                vehicle: {
                                    make,
                                    year,
                                    model,
                                    trim,
                                    engine,
                                    category,
                                    subcategory,
                                },
                            },
                        };
                    }

                    return {
                        ...row,
                        product: {
                            ...row.product,
                        },
                    };
                }),
            };

            if (!formik.values.id) {
                const { quoteId } = await postQuote(valuesParsed);
                await sendQuoteEmail({ ...valuesParsed, quoteId });
            }
        },
    });

    const postQuote = async (body) => {
        try {
            const quote = await QuoteService.createWithDetails(body);
            onSuccess(quote);
            return quote;
        } catch (error) {
            console.error(error);
        }
    };

    // Función para abrir el diálogo de selección de productos
    const handlePartNumberSearch = (partNumber, rowIndex) => {
        openDialog({
            customDialog: true,
            fullWidth: true,
            maxWidth: "md",
            message: (
                <PartSelectionDialog
                    partNumber={partNumber}
                    onProductSelect={(product) => {
                        const { name, price: { price } = {}, pathImage } = product;

                        // Solo guardamos campos específicos y no `product` directamente

                        formik.setFieldValue(`rows[${rowIndex}].itemName`, name);
                        formik.setFieldValue(`rows[${rowIndex}].price`, price);
                        formik.setFieldValue(`rows[${rowIndex}].pathImage`, pathImage);
                        formik.setFieldValue(`rows[${rowIndex}].qty`, 1);
                        formik.setFieldValue(`rows[${rowIndex}].product`, product);

                        closeDialog();
                    }}
                />
            ),
        });
    };

    const handlePartNumberBlur = (value, rowIndex, columnId) => {
        formik.setFieldValue(`rows[${rowIndex}].${columnId}`, value);
    };

    const columns = [
        {
            Header: "Part Number",
            accessor: "partNumber",
            Cell: ({ row, column }) => (
                <TextFieldTableCell
                    fullWidth
                    name={`rows[${row.index}].${column.id}`}
                    value={formik.values.rows[row.index][column.id]}
                    onBlur={(value) => handlePartNumberBlur(value, row.index, column.id)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() =>
                                        handlePartNumberSearch(
                                            formik.values.rows[row.index].partNumber,
                                            row.index
                                        )
                                    }
                                    edge="end"
                                >
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            ),
        },
        {
            Header: "Item Name",
            accessor: "itemName",
            Cell: ({ row, column }) => (
                <TextFieldTableCell
                    fullWidth
                    name={`rows[${row.index}].${column.id}`}
                    value={formik.values.rows[row.index][column.id]}
                    onBlur={(value) =>
                        formik.setFieldValue(`rows[${row.index}].${column.id}`, value)
                    }
                />
            ),
        },
        {
            Header: "Price",
            accessor: "price",
            Cell: ({ row, column }) => (
                <TextFieldTableCell
                    fullWidth
                    type="number"
                    name={`rows[${row.index}].${column.id}`}
                    value={formik.values.rows[row.index][column.id]}
                    onBlur={(value) =>
                        formik.setFieldValue(`rows[${row.index}].${column.id}`, value)
                    }
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                />
            ),
        },
        {
            Header: "Install",
            accessor: "install",
            Cell: ({ row, column }) => (
                <TextFieldTableCell
                    fullWidth
                    type="number"
                    name={`rows[${row.index}].${column.id}`}
                    value={formik.values.rows[row.index][column.id]}
                    onBlur={(value) =>
                        formik.setFieldValue(`rows[${row.index}].${column.id}`, value)
                    }
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                />
            ),
        },
        {
            Header: "Qty",
            accessor: "qty",
            Cell: ({ row, column }) => (
                <TextFieldTableCell
                    fullWidth
                    type="number"
                    name={`rows[${row.index}].${column.id}`}
                    value={formik.values.rows[row.index][column.id]}
                    onBlur={(value) =>
                        formik.setFieldValue(`rows[${row.index}].${column.id}`, value)
                    }
                />
            ),
        },
    ];

    return (
        <Box>
            <form onSubmit={formik.handleSubmit}>
                <GridContainer>
                    <GridItem xs={6} sm={6} md={6}>
                        <TextFieldRender
                            fullWidth
                            margin="dense"
                            id="quoteRef"
                            name="quoteRef"
                            label="Quote Ref#"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.quoteRef}
                            error={formik.touched.quoteRef && Boolean(formik.errors.quoteRef)}
                            helperText={formik.touched.quoteRef && formik.errors.quoteRef}
                        />
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                        <TextFieldRender
                            fullWidth
                            margin="dense"
                            id="labelManager"
                            name="labelManager"
                            label="Assign Label Manager"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.labelManager}
                            error={
                                formik.touched.labelManager && Boolean(formik.errors.labelManager)
                            }
                            helperText={formik.touched.labelManager && formik.errors.labelManager}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <TextFieldRender
                            fullWidth
                            multiline
                            margin="dense"
                            id="description"
                            name="description"
                            label="Description"
                            rows={2}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.description}
                        />
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                        <TextFieldRender
                            fullWidth
                            margin="dense"
                            id="customerName"
                            name="customerName"
                            label="Customer Name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.customerName}
                            error={
                                formik.touched.customerName && Boolean(formik.errors.customerName)
                            }
                            helperText={formik.touched.customerName && formik.errors.customerName}
                        />
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                        <TextFieldRender
                            fullWidth
                            margin="dense"
                            id="customerEmail"
                            name="customerEmail"
                            label="Customer Email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.customerEmail}
                            error={
                                formik.touched.customerEmail && Boolean(formik.errors.customerEmail)
                            }
                            helperText={formik.touched.customerEmail && formik.errors.customerEmail}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <Table
                            columns={columns}
                            data={formik.values.rows}
                            fetchData={() => {}}
                            pageCount={1}
                        />
                        {formik.errors.rows && (
                            <Typography color="error" variant="body2" style={{ marginTop: 8 }}>
                                {formik.errors.rows}
                            </Typography>
                        )}
                    </GridItem>
                    {!formik.values.id && (
                        <GridItem
                            xs={12}
                            sm={12}
                            md={12}
                            style={{ textAlign: "center", marginTop: 10 }}
                        >
                            <Button type="submit" color="rose">
                                Save and close
                            </Button>
                        </GridItem>
                    )}
                </GridContainer>
            </form>
        </Box>
    );
}
