import { useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
    Box,
    Paper,
    Typography,
    TextField,
    FormControl,
    InputLabel,
    Select,
    InputAdornment,
} from "@material-ui/core";
import { financial } from "helpers";
import useShipCalculator from "hooks/useShipCalculator";
import GenericSelect from "components/GenericSelect/GenericSelect";
import InfoButton from "components/InfoButton/InfoButton";

const StyledBox = withStyles(() => ({
    root: {
        marginBottom: 2,
    },
}))(Box);

const StyledTypography = withStyles(() => ({
    root: {
        display: "inline",
        fontSize: 14,
    },
}))(Typography);

const StyledTextField = withStyles(() => ({
    root: {
        width: 120,
    },
}))(TextField);

const useStyles = makeStyles(() => ({
    tableStyle: {
        "& tr td:nth-child(3)": {
            paddingLeft: 10,
        },
    },
    hiddenInput: {
        width: "100%",
        borderTop: "none",
        borderLeft: "none",
        borderRight: "none",
        borderBottom: "1px solid #767676",
        "&:focus": {
            border: "1px solid #767676",
        },
    },
}));

export default function ShippingCarrierCalculator({ order }) {
    const shipCalculator = useShipCalculator(order.details);
    const [handlingFee, setHandlingFee] = useState(order.handlingFeeAmount);
    const [customFeeAmount, setCustomFeeAmount] = useState(order.customFeeAmount);
    const [shipAmount, setShipAmount] = useState(order.shippingAmount || 0);
    const [salesTaxAmount, setSalesTaxAmount] = useState(order.salesTaxAmount);
    const [carrierId, setCarrierId] = useState("");
    const classes = useStyles();

    useEffect(() => {
        //setCustomFeeAmount(shipCalculator.shipAmount);
    }, [shipCalculator.shipAmount]);

    const subtotalAmount = order.details
        .filter((p) => p.qty - p.qtyShipped > 0)
        .reduce(
            (accumulator, { price, qty, qtyShipped }) =>
                accumulator + price * parseInt(qty - qtyShipped),
            0
        );

    const total =
        parseFloat(subtotalAmount) +
        parseFloat(handlingFee || 0) +
        parseFloat(customFeeAmount) +
        parseFloat(shipAmount) +
        parseFloat(salesTaxAmount);

    return (
        <>
            <Box display="flex">
                <Typography style={{ fontWeight: "bold", fontSize: 16 }} gutterBottom>
                    ORDER TOTAL SECTION
                </Typography>
                <InfoButton description="Shows the estimated breakdown of the total order cost." />
            </Box>
            <table width="100%" className={classes.tableStyle}>
                <tbody>
                    <tr>
                        <td>
                            <StyledTypography>Item(s) Subtotal:</StyledTypography>
                        </td>
                        <td align="right">
                            <StyledTextField
                                variant="outlined"
                                size="small"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">$</InputAdornment>
                                    ),
                                }}
                                value={financial(subtotalAmount)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <StyledTypography>Handling Fee:</StyledTypography>
                        </td>
                        <td align="right">
                            <StyledTextField
                                variant="outlined"
                                size="small"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">$</InputAdornment>
                                    ),
                                }}
                                value={financial(handlingFee)}
                                onChange={(e) => {
                                    setHandlingFee(e.target.value);
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <StyledTypography>Custom Fee:</StyledTypography>
                        </td>
                        <td align="right">
                            <StyledTextField
                                variant="outlined"
                                size="small"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">$</InputAdornment>
                                    ),
                                }}
                                value={financial(customFeeAmount)}
                                onChange={(e) => {
                                    setCustomFeeAmount(e.target.value);
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <StyledTypography>
                                Shipping ({order.shippingMethodName}):
                            </StyledTypography>
                        </td>
                        <td align="right">
                            <StyledTextField
                                variant="outlined"
                                size="small"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">$</InputAdornment>
                                    ),
                                }}
                                value={financial(shipAmount)}
                                onChange={(e) => {
                                    setShipAmount(e.target.value);
                                }}
                            />
                        </td>
                    </tr>
                    {/*<tr>
                        <td>
                            <StyledTypography>Carrier:</StyledTypography>
                        </td>
                        <td align="right">
                            <GenericSelect
                                variant="outlined"
                                size="small"
                                name="pagePosition"
                                value={carrierId}
                                onChange={(e) => setCarrierId(e.target.value)}
                                options={[
                                    {
                                        value: 1,
                                        label: "UPS",
                                    },
                                    {
                                        value: 2,
                                        label: "Fedex",
                                    },
                                ]}
                                style={{ width: 120 }}
                            />
                        </td>
                    </tr>*/}
                    <tr>
                        <td>
                            <StyledTypography>Sales tax:</StyledTypography>
                        </td>
                        <td align="right">
                            <StyledTextField
                                variant="outlined"
                                size="small"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">$</InputAdornment>
                                    ),
                                }}
                                value={financial(salesTaxAmount)}
                                onChange={(e) => {
                                    setSalesTaxAmount(e.target.value);
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <StyledTypography style={{ fontWeight: "bold" }}>
                                ORDER TOTAL:
                            </StyledTypography>
                        </td>
                        <td align="right">
                            <StyledTextField
                                variant="outlined"
                                size="small"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">$</InputAdornment>
                                    ),
                                }}
                                value={financial(total)}
                                onChange={(e) => {}}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    );

    return (
        <table width="100%" className={classes.tableStyle}>
            <tbody>
                <tr>
                    <td>
                        <StyledTypography>Subtotal:</StyledTypography>
                    </td>
                    <td>
                        <input
                            readOnly
                            className={classes.hiddenInput}
                            value={financial(subtotalAmount)}
                        />
                    </td>
                    <td>
                        <StyledTypography>Invoice number:</StyledTypography>
                    </td>
                    <td>
                        <input className={classes.hiddenInput} />
                    </td>
                </tr>
                <tr>
                    <td>
                        <StyledTypography>Handling Fee:</StyledTypography>
                    </td>
                    <td>
                        <Box style={{ display: "flex" }}>
                            <input
                                className={classes.hiddenInput}
                                value={handlingFee}
                                onChange={(e) => {
                                    setHandlingFee(e.target.value);
                                }}
                            />
                        </Box>
                    </td>
                    <td>
                        <StyledTypography>Assign Label To Order:</StyledTypography>
                    </td>
                    <td>
                        <input className={classes.hiddenInput} />
                    </td>
                </tr>
                <tr>
                    <td>
                        <StyledTypography>Custom Fee:</StyledTypography>
                    </td>
                    <td>
                        <input
                            className={classes.hiddenInput}
                            value={financial(customFeeAmount)}
                            onChange={() => {}}
                        />
                    </td>
                    <td>
                        <StyledTypography>Tracking Number:</StyledTypography>
                    </td>
                    <td>
                        <input className={classes.hiddenInput} />
                    </td>
                </tr>
                <tr>
                    <td>
                        <StyledTypography>Pickup Shipping Total:</StyledTypography>
                    </td>
                    <td>
                        <input
                            className={classes.hiddenInput}
                            value={financial(shipAmount)}
                            onChange={() => {}}
                        />
                    </td>
                    <td>
                        <StyledTypography>Carrier:</StyledTypography>
                    </td>
                    <td>
                        <select style={{ width: "100%" }}>
                            <option>Select carrier</option>
                            <option>UPS</option>
                            <option>FEDEX</option>
                        </select>
                    </td>
                </tr>
                <tr>
                    <td>
                        <StyledTypography>Sales tax:</StyledTypography>
                    </td>
                    <td>
                        <input
                            className={classes.hiddenInput}
                            value={financial(salesTaxAmount)}
                            onChange={() => {}}
                        />
                    </td>
                    <td>
                        <StyledTypography>Carrier amount:</StyledTypography>
                    </td>
                    <td>
                        <StyledTypography>{shipAmount}</StyledTypography>
                    </td>
                </tr>
                <tr>
                    <td>
                        <StyledTypography>Order total:</StyledTypography>
                    </td>
                    <td>
                        <input
                            className={classes.hiddenInput}
                            value={financial(total)}
                            onChange={() => {}}
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    );
}
