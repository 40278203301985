import React from "react";
import { Redirect, Route } from "react-router-dom";

export default function ProtectedRoute({ component: Component, ...restOfProps }) {
    const isAuthenticated = localStorage.getItem("_gid");

    return (
        <Route
            {...restOfProps}
            render={(props) => {
                if (isAuthenticated) {
                    return <Component {...props} />;
                } else {
                    // Guardar la URL completa incluyendo el query string
                    const redirectPath = `${props.location.pathname}${props.location.search}`;
                    localStorage.setItem("redirectAfterLogin", redirectPath);
                    return <Redirect to="/auth/login-page" />;
                }
            }}
        />
    );
}
