import React, { useState, useEffect } from "react";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import Modal from "components/Modal/Modal.js";
import Notification from "components/Notification/Notification.js";
import AreYouSure from "components/AreYouSure/AreYouSure";
import CreatePaymentMethod from "./CreatePaymentMethod";

import PaymentMethodService from "../../services/PaymentMethodService";

export const CREATE_PAYMENT_METHOD = {
    title: "Create payment method",
    successMessage: "Payment method created successfully",
};

export const EDIT_PAYMENT_METHOD = {
    title: "Edit payment method",
    successMessage: "Payment method updated successfully",
};

export default function PaymentMethods() {
    const [open, setOpen] = useState(false);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [form, setForm] = useState(CREATE_PAYMENT_METHOD);
    const [openModal, setOpenModal] = useState(false);
    const [initialValues, setInitialValues] = useState({});
    const [notification, setNotification] = useState({
        open: false,
        color: "success",
    });

    useEffect(() => {
        const getPaymentMethods = async () => {
            const methods = await PaymentMethodService.getAll();
            setPaymentMethods(methods);
        };
        getPaymentMethods();
    }, []);

    const columns = [
        {
            Header: "Id",
            accessor: "id",
        },
        {
            Header: "Name",
            accessor: "name",
        },
        {
            Header: "Actions",
            Cell: ({ row: { original } }) => {
                return (
                    <>
                        <div className="actions-right">
                            <Button
                                justIcon
                                round
                                simple
                                color="warning"
                                className="edit"
                                title="Edit"
                                onClick={() => {
                                    setForm(EDIT_PAYMENT_METHOD);
                                    setOpenModal(true);
                                    setInitialValues(original);
                                }}
                            >
                                <Edit />
                            </Button>
                            <Button
                                justIcon
                                round
                                simple
                                color="danger"
                                className="edit"
                                title="Delete"
                                onClick={() => {
                                    setInitialValues(original);
                                    setOpen(true);
                                }}
                            >
                                <Close />
                            </Button>
                        </div>
                    </>
                );
            },
        },
    ];

    const onSuccess = (method) => {
        if (form === CREATE_PAYMENT_METHOD) {
            setPaymentMethods([...paymentMethods, method]);
        }

        if (form === EDIT_PAYMENT_METHOD) {
            const updatedMethods = paymentMethods.map((m) =>
                m.id === method.id ? { ...m, ...method } : m
            );
            setPaymentMethods(updatedMethods);
        }

        setInitialValues({});
        setOpenModal(false);
        setNotification({
            ...notification,
            open: true,
            message: form.successMessage,
        });
    };

    return (
        <>
            {notification.open && (
                <Notification
                    open={notification.open}
                    color={notification.color}
                    message={notification.message}
                />
            )}

            <Modal open={openModal} modalTitle={form.title} handleClose={setOpenModal}>
                <div style={{ minWidth: 800 }}></div>
                <CreatePaymentMethod
                    form={form}
                    initialValues={initialValues}
                    onSuccess={onSuccess}
                />
            </Modal>

            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="primary" icon>
                            <CardIcon color="primary">
                                <Assignment />
                            </CardIcon>
                            <Button
                                color="primary"
                                onClick={() => {
                                    setForm(CREATE_PAYMENT_METHOD);
                                    setOpenModal(true);
                                    setInitialValues({});
                                }}
                            >
                                Create payment method
                            </Button>
                        </CardHeader>
                        <CardBody>
                            <ReactTable
                                columns={columns}
                                data={paymentMethods}
                                showPagination={false}
                                showSorting={false}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>

            <AreYouSure
                open={open}
                title={`Are you sure?`}
                subtitle={`The record will be deleted`}
                onAccept={async () => {
                    await PaymentMethodService.del(initialValues.id);
                    setPaymentMethods(
                        [...paymentMethods].filter((method) => method.id !== initialValues.id)
                    );
                    setOpen(false);
                }}
                onCancel={() => setOpen(false)}
            />
        </>
    );
}
