import useSWR from "swr";
import CustomerService from "services/CustomerService";

const fetchCustomerProfile = async (customerId) => {
    const response = await CustomerService.get(customerId);
    return response.data;
};

const useCustomer = (customerId) => {
    const { data, error, mutate } = useSWR(
        customerId ? `customer-${customerId}` : null,
        () => fetchCustomerProfile(customerId),
        {
            revalidateOnFocus: false,
        }
    );

    return {
        customerProfile: data,
        loading: !data && !error,
        error,
        refetch: mutate,
    };
};

export default useCustomer;
