import { useEffect, useState } from "react";

const useShipCalculator = (products = []) => {
    const [shipAmount, setShipAmount] = useState(0);

    useEffect(() => {
        const weightedProducts = products
            .filter((p) => p.shipQty)
            .map((product) => product.itemWeight * 1.1 * product.shipQty);

        const amount = weightedProducts.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
        );
        setShipAmount(amount.toFixed(2));
    }, [products]);

    return {
        shipAmount,
        setShipAmount,
    };
};

export default useShipCalculator;
