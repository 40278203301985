import React, { useState } from "react";
import useSWR from "swr";
import { Box, Typography, Tabs, Tab } from "@material-ui/core";
import CreditCardForm from "components/CreditCardForm/CreditCardForm";
import DealerPaymentService from "services/DealerPaymentService";

export default function PaymentMethods({
    dealerId,
    amountToBeCharged = 0,
    billingInfo = {},
    onSuccess = () => {},
}) {
    const fetcher = () => DealerPaymentService.getAll(dealerId);
    const { data } = useSWR("paymentMethods", fetcher);
    const [value, setValue] = useState(0);

    const handleChange = (_, newValue) => {
        setValue(newValue);
    };

    const renderPaymentComponent = (_, name) => {
        switch (name) {
            case "PAYPAL-PRO":
            case "PayPal Pro":
                return (
                    <CreditCardForm
                        showConfirmation
                        amountToBeCharged={amountToBeCharged}
                        paymentProcessor="PayPalPro"
                        billingInfo={billingInfo}
                        onSuccess={onSuccess}
                    />
                );
            case "Authorize Net":
                return (
                    <CreditCardForm
                        showConfirmation
                        amountToBeCharged={amountToBeCharged}
                        paymentProcessor="AuthorizeNet"
                        billingInfo={billingInfo}
                        onSuccess={onSuccess}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <Box>
            {/* Renderizando las pestañas */}
            <Tabs value={value} onChange={handleChange} aria-label="Métodos de Pago">
                {data?.data
                    ?.filter((p) => p.name !== "PayPal Express")
                    .map((payment, index) => (
                        <Tab key={index} label={payment.paymentMethodName} />
                    ))}
            </Tabs>

            {/* Mostrando el contenido según la pestaña seleccionada */}
            {data?.data?.map((payment, index) => (
                <div
                    key={index}
                    role="tabpanel"
                    hidden={value !== index}
                    id={`tabpanel-${index}`}
                    aria-labelledby={`tab-${index}`}
                >
                    {value === index && (
                        <Box p={3}>
                            {renderPaymentComponent(
                                payment.payment_method_id,
                                payment.paymentMethodName
                            )}
                        </Box>
                    )}
                </div>
            ))}
        </Box>
    );
}
