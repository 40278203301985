import { useState } from "react";
import PaymentService from "services/PaymentService";
import { financial } from "helpers";

const TRANSACTION_OK = "0";

export default function usePaymentHandler() {
    const [loading, setLoading] = useState(false);

    const postPaypalPro = async (body, onSuccess = () => {}, onError = () => {}) => {
        setLoading(true);

        try {
            const res = await PaymentService.postPaypalPro(body);

            if (res.RESULT === TRANSACTION_OK) {
                onSuccess(
                    `The payment has been successfully processed. The amount of $${financial(
                        body?.transactionAmount
                    )} has been charged to the customer's account.`
                );
            } else {
                onError(
                    "Transaction Failed: Your payment could not be processed. Please check your card details or try a different payment method. If the issue persists, contact customer support."
                );
            }

            return res;
        } catch (error) {
            onError(
                "The action could not be completed due to an issue with the initial transaction information. This may be related to missing payment gateway implementation or missing credit card (CC) information in the order."
            );
        } finally {
            setLoading(false);
        }
    };

    const postAuthorizeNet = async (body, onSuccess = () => {}, onError = () => {}) => {
        setLoading(true);
        try {
            const res = await PaymentService.postAuthorizeNet(body);

            onSuccess(
                `The payment has been successfully processed. The amount of $${financial(
                    body?.transactionAmount
                )} has been charged to the customer's account.`
            );

            return res;
        } catch (error) {
            onError(
                "The action could not be completed due to an issue with the initial transaction information. This may be related to missing payment gateway implementation or missing credit card (CC) information in the order."
            );
        } finally {
            setLoading(false);
        }
    };

    return {
        loading,
        postPaypalPro,
        postAuthorizeNet,
    };
}
