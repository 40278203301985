import React from "react";
import Button from "components/CustomButtons/Button.js";
import { Link, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import PublishIcon from "@material-ui/icons/Publish";
import { useConfirmationDialog } from "hooks/useConfirmationDialog";
import VhostsService from "services/VhostsService";

export default function GoLiveButton({ id = "", dealerDomain = "", isPublished = 0 }) {
    const { openDialog } = useConfirmationDialog();

    const handlePublishStore = async () => {
        if (!dealerDomain) {
            alert("Please define a store URL before publishing.");
            return;
        }

        try {
            const response = await VhostsService.setupVHosts(id, dealerDomain);
            alert(`Store published successfully: ${response}`);
        } catch (error) {
            alert(`Failed to publish store: ${error.message}`);
        }
    };

    const handleClickGoLive = (e) => {
        e.preventDefault();

        openDialog({
            customDialog: true,
            showActions: true,
            title: (
                <Typography variant="h6" gutterBottom>
                    Are you sure you want to publish your store?
                </Typography>
            ),
            message: (
                <>
                    {!dealerDomain ? (
                        <Alert severity="error">
                            The store URL is not defined. Please check your store settings and try
                            again.
                        </Alert>
                    ) : (
                        <Typography variant="body1" style={{ textAlign: "justify" }}>
                            This will create the necessary configurations, including HTTP to HTTPS
                            redirection for the store URL:
                            <Link
                                href={`https://${dealerDomain}/en?dlid=${id}`}
                                target="_blank"
                                color="primary"
                                style={{
                                    display: "block",
                                    marginTop: 5,
                                }}
                            >
                                {dealerDomain}
                            </Link>
                        </Typography>
                    )}
                </>
            ),
            onConfirm: handlePublishStore,
            confirmButtonStyle: !dealerDomain ? { display: "none" } : {},
        });
    };

    if (!id || isPublished === 1) return null;

    return (
        <Button
            color="facebook"
            onClick={handleClickGoLive}
            style={{
                marginLeft: "auto",
            }}
        >
            Go Live <PublishIcon style={{ margin: 0 }} />
        </Button>
    );
}
