import http from "./Http";

const setupVHosts = async (dealerId, storeName) => {
    const res = await http.post(`/vhosts`, {
        dealerId,
        storeName: storeName,
    });

    return res.data;
};

const VhostsService = {
    setupVHosts,
};

export default VhostsService;
