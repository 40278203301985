import { useMemo } from "react";

const useCreditCardMasks = () => {
    const cardNumberMask = useMemo(
        () => [
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            " ",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            " ",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            " ",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
        ],
        []
    );

    const cardValidThruMask = useMemo(() => [/\d/, /\d/, "/", /\d/, /\d/], []);

    const cardCvcMask = useMemo(() => [/\d/, /\d/, /\d/], []);

    return {
        cardNumberMask,
        cardValidThruMask,
        cardCvcMask,
    };
};

export default useCreditCardMasks;
