import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: "100%", // Asegura que el contenedor no exceda el 100% del ancho disponible
        "& > * + *": {
            marginTop: theme.spacing(2),
        },
    },
    alert: {
        maxWidth: "100%", // Se asegura que el alert no exceda el contenedor del modal
    },
}));

// Enum for alert severity
export const AlertSeverity = {
    SUCCESS: "success",
    ERROR: "error",
    WARNING: "warning",
    INFO: "info",
};

export default function ActionAlerts({ message, severity, autoCloseTime = 5000, onClose }) {
    const classes = useStyles();
    const [open, setOpen] = useState(true);

    useEffect(() => {
        if (autoCloseTime) {
            const timer = setTimeout(() => {
                setOpen(false);
                if (onClose) {
                    onClose();
                }
            }, autoCloseTime);

            return () => clearTimeout(timer);
        }
    }, [autoCloseTime, onClose]);

    const handleClose = () => {
        setOpen(false);
        if (onClose) {
            onClose();
        }
    };

    if (!open) return null;

    return (
        <div className={classes.root}>
            <Alert severity={severity} onClose={handleClose} className={classes.alert}>
                {message}
            </Alert>
        </div>
    );
}

ActionAlerts.propTypes = {
    message: PropTypes.string.isRequired,
    severity: PropTypes.oneOf([
        AlertSeverity.SUCCESS,
        AlertSeverity.ERROR,
        AlertSeverity.WARNING,
        AlertSeverity.INFO,
    ]).isRequired,
    autoCloseTime: PropTypes.number,
    onClose: PropTypes.func,
};
