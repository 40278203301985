import http from "./Http";

const getAll = async (dealerId, params = {}) => {
    const queryParams = new URLSearchParams({ dealerId, ...params }).toString();
    return http.get(`/dealer-coupons?${queryParams}`);
};

const post = async (body) => {
    return http.post("/dealer-coupons", body);
};

const put = async (body) => {
    return http.put(`/dealer-coupons/${body.id}`, body);
};

const del = async (id) => {
    return http.delete(`/dealer-coupons/${id}`);
};

const DealerCouponsService = {
    getAll,
    post,
    put,
    del,
};

export default DealerCouponsService;
