import { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";

export default function TextFieldTableCell({ value, onBlur, ...props }) {
    const [internalValue, setInternalValue] = useState(value || "");

    useEffect(() => {
        setInternalValue(value || "");
    }, [value]);

    const handleChange = (e) => {
        setInternalValue(e.target.value);
    };

    const handleBlur = () => {
        onBlur(internalValue);
    };

    return (
        <TextField {...props} value={internalValue} onChange={handleChange} onBlur={handleBlur} />
    );
}
