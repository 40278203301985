import http from "./Http";

const getAll = async (dlid) => {
    return http.get(`/dealer-pgateway/${dlid}`);
};

const post = async (body) => {
    return http.post(`/dealer-pgateway`, body).then((r) => r.data);
};

const put = async (body) => {
    return http.put(`/dealer-pgateway/${body.id}`, body);
};

const del = async (id) => {
    return http.delete(`/dealer-pgateway/${id}`);
};

const DealerPaymentService = {
    getAll,
    post,
    put,
    del,
};

export default DealerPaymentService;
