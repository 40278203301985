import React, { useCallback, useState, useEffect } from "react";
import PageLayout from "layouts/PageLayout";
import { Box } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import TableWrapper from "components/TableWrapper/TableWrapper";
import BasicDialog from "components/BasicDialog/BasicDialog";
import useDealerCoupons from "hooks/useDealerCoupons";
import useDialog from "hooks/useDialog";
import CreateCoupon from "./CreateCoupon";
import useDealer from "hooks/useDealer";
import { financial } from "helpers";

const renderCouponType = (coupon) => {
    switch (coupon.couponType) {
        case "fixed_price":
            return `Fixed Price - $${financial(coupon.discountAmount) || 0}`;
        case "percentage":
            return `Percentage - ${coupon.discountPercentage || 0}%`;
        case "free_shipping":
            return "Free Shipping";
        case "free_shipping_discount":
            return `Free Shipping + ${coupon.discountPercentage || 0}% off`;
        default:
            return coupon.couponType;
    }
};

export default function Coupon() {
    const dealer = useDealer();
    const { coupons = [], handleDeleteCoupon, fetchCoupons } = useDealerCoupons(dealer.id);
    const { open, setOpen } = useDialog();
    const [selectedCoupon, setSelectedCoupon] = useState(null);

    // Refrescar la lista de cupones cada vez que dealer.id cambie a un valor válido
    useEffect(() => {
        if (dealer.id && dealer.id !== 0) {
            fetchCoupons(); // Llama a la función de refresco cuando el dealer.id es válido
        }
    }, [dealer.id, fetchCoupons]);

    const handleSuccess = useCallback(() => {
        fetchCoupons();
        setOpen(false);
        setSelectedCoupon(null);
    }, [fetchCoupons, setOpen]);

    const handleEditClick = (coupon) => {
        setSelectedCoupon(coupon);
        setOpen(true);
    };

    const columns = [
        { Header: "Coupon Name", accessor: "couponName" },
        { Header: "Coupon Code", accessor: "couponCode" },
        {
            Header: "Coupon Type",
            accessor: "couponType",
            Cell: ({ row }) => renderCouponType(row.original),
        },
        { Header: "Start Date", accessor: "couponStartDate" },
        { Header: "End Date", accessor: "couponEndDate" },
        {
            Header: "Status",
            accessor: "couponActive",
            Cell: ({ cell }) => (cell.value ? "Active" : "Inactive"),
        },
    ];

    return (
        <PageLayout
            showSelectDealer
            customComponent={
                <Button
                    color="primary"
                    onClick={() => {
                        setSelectedCoupon(null);
                        setOpen(true);
                    }}
                >
                    Create Coupon
                </Button>
            }
        >
            <BasicDialog fullWidth open={open} onClose={() => setOpen(false)} maxWidth="md">
                {open && (
                    <CreateCoupon
                        onSuccess={handleSuccess}
                        initialValues={{ ...selectedCoupon, dealerId: dealer.id }}
                    />
                )}
            </BasicDialog>

            <Box>
                <TableWrapper
                    showEditButton
                    showDeleteButton
                    columns={columns}
                    data={coupons}
                    onEditClick={handleEditClick}
                    onDeleteClick={(id) => handleDeleteCoupon(id)}
                />
            </Box>
        </PageLayout>
    );
}
