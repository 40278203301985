import { useState, useEffect } from "react";
import useUser from "./useUser";
import MenuService from "services/MenuService";
import useSWR from "swr";

//Icons
import * as Icons from "@material-ui/icons";

// Función para convertir el array a árbol
const arrayToTree = (arr, parent = 0) => {
    return arr
        .filter((item) => item.parentMenuId === parent)
        .map((child) => {
            const { id, icon } = child;

            return {
                ...child,
                views: arrayToTree(arr, id)
                    .sort((a, b) => a.orderMenu - b.orderMenu)
                    .map((_menu) => {
                        const { id, views = [] } = _menu;

                        return {
                            ..._menu,
                            ...(views.length > 0 && {
                                collapse: true,
                                state: `${id}Collapse`,
                            }),
                        };
                    }),
                ...(icon && {
                    icon: Icons[icon],
                }),
            };
        });
};

// Función para hacer fetch de los menús
const fetchMenu = async () => {
    let arrMenu = await MenuService.getAll();
    arrMenu = arrMenu
        .filter((m) => m.name !== "Translate Manager")
        .map((m) => ({ ...m, views: [] }));

    arrMenu.sort((a, b) => a.orderMenu - b.orderMenu);
    return arrMenu;
};

const useMenu = (assignedRolesOnly = false) => {
    const [user] = useUser();

    // Usar SWR para obtener el menú
    const { data: arrMenu, error } = useSWR("menu", fetchMenu);

    const [menu, setMenu] = useState({
        arrMenu: [],
        treeMenu: [],
    });

    useEffect(() => {
        if (!arrMenu) return;

        // Filtrar si assignedRolesOnly es true
        let filteredMenu = arrMenu;

        if (assignedRolesOnly) {
            const menuOptions = user?.menuOptions || [];
            filteredMenu = arrMenu.filter((m) => menuOptions.includes(m.id.toString()));
        }

        // Convertir el menú filtrado en un árbol
        const treeMenu = arrayToTree(filteredMenu, 0).map((_menu) => {
            const { id, views = [] } = _menu;

            return {
                id,
                ..._menu,
                ...(views.length > 0 && {
                    collapse: true,
                    state: `${id}Collapse`,
                }),
            };
        });

        // Actualizar el estado del menú
        setMenu({
            arrMenu: filteredMenu,
            treeMenu,
        });
    }, [arrMenu, assignedRolesOnly, user]);

    // Manejar posibles errores en la obtención del menú
    if (error) return { menu: null, error };

    return menu;
};

export default useMenu;
