import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

// @material-ui/icons
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

// const
import { CREATE_DEALER_PAYMENT, EDIT_DEALER_PAYMENT } from "views/Dealers/Tabs/DealerPayments";

// services
import DealerPaymentService from "../../services/DealerPaymentService";
import PaymentMethodService from "../../services/PaymentMethodService";
import PaymentMethodFields from "./PaymentMethodFields";

const validationSchema = yup.object({
    paymentMethodId: yup.string().required("Name is required"),
    name: yup.string().required("Name is required"),
    gatewayCredentials: yup
        .string()
        .required("Gateway Credentials is required")
        .test("valid-json", "Invalid JSON format", function (value) {
            try {
                const parsedValue = JSON.parse(value);
                return typeof parsedValue === "object";
            } catch (error) {
                return false;
            }
        }),
});

const useStyles = makeStyles(styles);

export default function CreateDealerPayment({
    dlid,
    form = CREATE_DEALER_PAYMENT,
    initialValues,
    onSuccess,
}) {
    const classes = useStyles();
    const [paymentMethods, setPaymentMethods] = useState([]);

    useEffect(() => {
        const getPaymentMethods = async () => {
            const methods = await PaymentMethodService.getAll();
            setPaymentMethods(methods);
        };
        getPaymentMethods();
    }, []);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: initialValues.id || 0,
            name: initialValues.name || "",
            paymentMethodId: initialValues.paymentMethodId || 0,
            envMode: initialValues.envMode || "",
            gatewayCredentials: initialValues.gatewayCredentials || "",
            // Add other fields and their initial values
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (form === CREATE_DEALER_PAYMENT) createDealerPayment(values);
            if (form === EDIT_DEALER_PAYMENT) updateDealerPayment(values);
        },
    });

    const createDealerPayment = async (body) => {
        try {
            const dealerPayment = await DealerPaymentService.post({ ...body, dlid });
            onSuccess(dealerPayment);
        } catch (error) {
            console.error(error);
        }
    };

    const updateDealerPayment = async (body) => {
        try {
            const dealerPayment = await DealerPaymentService.put(body);
            onSuccess(dealerPayment);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <form onSubmit={formik.handleSubmit}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <FormControl
                                fullWidth
                                margin="dense"
                                id="name"
                                name="name"
                                error={formik.touched.name && Boolean(formik.errors.name)}
                            >
                                <InputLabel id="payment-method-label">Payment Method</InputLabel>
                                <Select
                                    labelId="payment-method-label"
                                    id="paymentMethodId"
                                    name="paymentMethodId"
                                    value={formik.values.paymentMethodId}
                                    onChange={(e) => {
                                        const pId = e.target.value;
                                        const { name = "" } = paymentMethods.find(
                                            (pm) => pm.id === pId
                                        );
                                        formik.setFieldValue("paymentMethodId", pId);
                                        formik.setFieldValue("name", name);
                                    }}
                                >
                                    {paymentMethods.map((method) => (
                                        <MenuItem key={method.id} value={method.id}>
                                            {method.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </GridItem>
                    </GridContainer>
                    <GridContainer style={{ display: "none" }}>
                        <GridItem xs={12} sm={12} md={12}>
                            <TextField
                                autoFocus
                                fullWidth
                                margin="dense"
                                id="name"
                                name="name"
                                label="Dealer Payment Name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />
                        </GridItem>
                    </GridContainer>

                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <TextField
                                select
                                autoFocus
                                fullWidth
                                margin="dense"
                                id="envMode"
                                name="envMode"
                                label="Env mode"
                                value={formik.values.envMode}
                                onChange={(e) => {
                                    const envMode = e.target.value;
                                    const {
                                        sandboxUrl = "",
                                        productionUrl = "",
                                    } = paymentMethods.find(
                                        (p) => p.id === formik.values.paymentMethodId
                                    );
                                    let updatedCredentials = {};

                                    try {
                                        if (formik.values.gatewayCredentials) {
                                            updatedCredentials = JSON.parse(
                                                formik.values.gatewayCredentials
                                            );
                                        }

                                        if (envMode === "sandbox") {
                                            updatedCredentials.apiUrl = sandboxUrl;
                                        } else if (envMode === "production") {
                                            updatedCredentials.apiUrl = productionUrl;
                                        }

                                        formik.setFieldValue("envMode", envMode);
                                        formik.setFieldValue(
                                            "gatewayCredentials",
                                            JSON.stringify(updatedCredentials)
                                        );
                                    } catch (error) {}
                                }}
                                error={formik.touched.envMode && Boolean(formik.errors.envMode)}
                                helperText={formik.touched.name && formik.errors.envMode}
                            >
                                <MenuItem value="">
                                    <em>Select Environment</em>
                                </MenuItem>
                                <MenuItem value="sandbox">Sandbox</MenuItem>
                                <MenuItem value="production">Production</MenuItem>
                            </TextField>
                        </GridItem>
                    </GridContainer>

                    {/* PaymentMethodFields*/}
                    <PaymentMethodFields
                        paymentMethodId={formik.values.paymentMethodId}
                        formik={formik}
                    />
                    <GridContainer style={{ display: "none" }}>
                        <GridItem xs={12} sm={12} md={12}>
                            <TextField
                                fullWidth
                                margin="dense"
                                id="gatewayCredentials"
                                name="gatewayCredentials"
                                label="Gateway Credentials (read-only for verification)"
                                multiline
                                rows={4}
                                value={formik.values.gatewayCredentials}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.gatewayCredentials &&
                                    Boolean(formik.errors.gatewayCredentials)
                                }
                                helperText={
                                    formik.touched.gatewayCredentials &&
                                    formik.errors.gatewayCredentials
                                }
                                inputProps={{ readOnly: true }}
                            />
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem
                            xs={12}
                            sm={12}
                            md={12}
                            style={{ textAlign: "center", marginTop: 5 }}
                        >
                            <Button type="submit" color="rose">
                                Save and close
                            </Button>
                        </GridItem>
                    </GridContainer>
                </form>
            </GridItem>
        </GridContainer>
    );
}
