import React from "react";
import {
    Box,
    InputAdornment,
    FormControl,
    FormLabel,
    FormGroup,
    FormControlLabel,
    Checkbox,
} from "@material-ui/core";
import TextFieldRender from "components/TextFieldRender/TextFieldRender";
import Button from "components/CustomButtons/Button.js";
import useAllStateTax from "hooks/useAllStateTax";

export default function AllStatesTax() {
    const { formik } = useAllStateTax();

    return (
        <form onSubmit={formik.handleSubmit}>
            <Box>
                <TextFieldRender
                    type="number"
                    label="All States/Province Tax Rate"
                    name="allStatesProvinceTaxRate"
                    value={formik.values.allStatesProvinceTaxRate}
                    handleBlur={(value) =>
                        formik.setFieldValue("allStatesProvinceTaxRate", parseFloat(value))
                    }
                    error={
                        formik.touched.allStatesProvinceTaxRate &&
                        Boolean(formik.errors.allStatesProvinceTaxRate)
                    }
                    InputProps={{
                        endAdornment: <InputAdornment position="start">%</InputAdornment>,
                    }}
                />
            </Box>
            <Box mt={2.5}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Tax Option:</FormLabel>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.applyTaxToPartsAccessories === 1}
                                    onChange={(e) =>
                                        formik.setFieldValue(
                                            "applyTaxToPartsAccessories",
                                            e.target.checked ? 1 : 0
                                        )
                                    }
                                />
                            }
                            label="  Apply tax to parts/accessories"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.applyTaxToShipping === 1}
                                    onChange={(e) =>
                                        formik.setFieldValue(
                                            "applyTaxToShipping",
                                            e.target.checked ? 1 : 0
                                        )
                                    }
                                />
                            }
                            label="  Apply tax to shipping"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.applyLaborOnOrderTotal === 1}
                                    onChange={(e) =>
                                        formik.setFieldValue(
                                            "applyLaborOnOrderTotal",
                                            e.target.checked ? 1 : 0
                                        )
                                    }
                                />
                            }
                            label="Apply Labor on Order Total"
                        />
                    </FormGroup>
                </FormControl>
            </Box>
            <Box textAlign="center" mt={2}>
                <Button type="submit" color="rose">
                    Update
                </Button>
            </Box>
        </form>
    );
}
