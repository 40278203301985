import { useCallback } from "react";
import useEmail from "./useEmail";
import useCrud from "./useCrud";

const useSendQuoteEmail = () => {
    const { sendEmailAsync } = useEmail();
    const { readAllEntities: fetchDealer } = useCrud("dealers", {}, false);

    const sendQuoteEmail = useCallback(
        async (quoteData = {}) => {
            const dealer = await fetchDealer({ id: quoteData.dealerId || 56 });

            if (dealer.length === 0) {
                console.error("No dealer found.");
                return null;
            }

            const { company, dealerDomain } = dealer[0];
            const checkoutUrl = `https://${dealerDomain}/checkout?qn=${quoteData.quoteId}`;
            const recipients = quoteData.customerEmail;
            const subject = `Quote Confirmation: ${quoteData.quoteRef}`;

            // Filtra solo los elementos con `partNumber` válido y crea el arreglo de items
            const items = (quoteData.rows || [])
                .filter((row) => row.partNumber) // Filtra solo las filas con `partNumber`
                .map((row) => ({
                    itemName: row.itemName || "N/A",
                    partNumber: row.partNumber,
                    installPrice: Number(row.install || 0), // Convierte a número
                    qty: Number(row.qty || 1), // Convierte a número
                    price: Number(row.price || 0), // Convierte a número
                }));

            // Cálculos de subtotal y precio de instalación
            const itemSubtotal = items.reduce((total, item) => total + item.price * item.qty, 0);
            const installTotal = items.reduce((total, item) => total + item.installPrice, 0);
            const grandTotal = itemSubtotal + installTotal;

            const messageBody = `
                <div style="font-size: 15px;">
                    <b>Quote #${quoteData.quoteRef || "7712"}</b>

                    <br><br>
                    Thank you for visiting <b>${company}</b>. Here is a list of all Items you inquired about.

                    <br><br>

                    <table style="width: 100%; border-collapse: collapse; text-align: right;">
                        <thead>
                            <tr>
                                <th style="border: 1px solid #ddd; padding: 8px; text-align: left;">Item Name</th>
                                <th style="border: 1px solid #ddd; padding: 8px; text-align: left;">Part #</th>
                                <th style="border: 1px solid #ddd; padding: 8px; text-align: right;">Installation Price</th>
                                <th style="border: 1px solid #ddd; padding: 8px; text-align: right;">Qty</th>
                                <th style="border: 1px solid #ddd; padding: 8px; text-align: right;">Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            ${items
                                .map(
                                    (item) => `
                                        <tr>
                                            <td style="border: 1px solid #ddd; padding: 8px; text-align: left;">${
                                                item.itemName
                                            }</td>
                                            <td style="border: 1px solid #ddd; padding: 8px; text-align: left;">${
                                                item.partNumber
                                            }</td>
                                            <td style="border: 1px solid #ddd; padding: 8px;">$${item.installPrice.toFixed(
                                                2
                                            )}</td>
                                            <td style="border: 1px solid #ddd; padding: 8px;">${
                                                item.qty
                                            }</td>
                                            <td style="border: 1px solid #ddd; padding: 8px;">$${(
                                                item.price * item.qty
                                            ).toFixed(2)}</td>
                                        </tr>
                                    `
                                )
                                .join("")}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colspan="4" style="border: 1px solid #ddd; padding: 8px; text-align: right; font-weight: bold;">Item Subtotal</td>
                                <td style="border: 1px solid #ddd; padding: 8px;">$${itemSubtotal.toFixed(
                                    2
                                )}</td>
                            </tr>
                            <tr>
                                <td colspan="4" style="border: 1px solid #ddd; padding: 8px; text-align: right; font-weight: bold;">Install Price</td>
                                <td style="border: 1px solid #ddd; padding: 8px;">$${installTotal.toFixed(
                                    2
                                )}</td>
                            </tr>
                            <tr>
                                <td colspan="4" style="border: 1px solid #ddd; padding: 8px; text-align: right; font-weight: bold;">Grand Total</td>
                                <td style="border: 1px solid #ddd; padding: 8px;">$${grandTotal.toFixed(
                                    2
                                )}</td>
                            </tr>
                        </tfoot>
                    </table>

                    <br><br>
                    The price shown are only quotes and are subject to change. For more information please reach out to for more information.

                    <br>
                    <a href="${checkoutUrl}" target="_blank" style="color: #1a73e8; font-size: 18px; text-decoration: underline;">
                        Click here to add this quote to your cart.
                    </a>

                    <br><br><br>
                    <strong>Description/Notes</strong>
                    <br><br>
                    ${quoteData.description || "No additional description provided."}
                </div>
            `;

            try {
                await sendEmailAsync(recipients, "", subject, messageBody);
            } catch (error) {
                console.error("Error sending quote email:", error);
            }
        },
        [sendEmailAsync, fetchDealer]
    );

    return {
        sendQuoteEmail,
    };
};

export default useSendQuoteEmail;
