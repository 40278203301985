import React, { useEffect, useRef } from "react";
import {
    Box,
    Typography,
    MenuItem,
    Switch,
    FormControlLabel,
    Select,
    InputLabel,
    FormControl,
    TextField,
} from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import CalendarPicker from "components/CalendarPicker/CalendarPicker";
import { useSnackbar } from "hooks/useSnackbar";
import { useFormik } from "formik";
import * as yup from "yup";
import DealerCouponsService from "services/dealerCouponsService";

// Esquema de validación Yup
const validationSchema = yup.object({
    couponName: yup.string().required("Coupon Name is required"),
    couponCode: yup.string().required("Coupon Code is required"),
    couponType: yup.string().required("Coupon Type is required"),
    couponStartDate: yup.date().required("Start Date is required"),
    couponEndDate: yup.date().required("End Date is required"),
    utmCode: yup.string(),
    discountAmount: yup
        .number()
        .nullable()
        .when("couponType", {
            is: "fixed_price",
            then: yup.number().required("Discount amount is required for Fixed Price Coupon"),
        }),
    discountPercentage: yup
        .number()
        .nullable()
        .when("couponType", {
            is: (val) => val === "percentage" || val === "free_shipping_discount",
            then: yup.number().required("Discount percentage is required").min(1).max(100),
        }),
});

export default function CreateCoupon({ onSuccess = () => {}, initialValues = {} }) {
    const isMounted = useRef(true);
    const openSnackbar = useSnackbar();

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    const formik = useFormik({
        initialValues: {
            dealerId: initialValues.dealerId || null,
            couponName: initialValues.couponName || "",
            couponCode: initialValues.couponCode || "",
            inStorePickup: Boolean(initialValues.inStorePickup),
            couponType: initialValues.couponType || "",
            couponStartDate: initialValues.couponStartDate || "",
            couponEndDate: initialValues.couponEndDate || "",
            oneCouponPerCustomer: Boolean(initialValues.oneCouponPerCustomer),
            couponActive: Boolean(initialValues.couponActive),
            utmCode: initialValues.utmCode || "",
            applyCouponOn: initialValues.applyCouponOn || "",
            discountAmount: initialValues.discountAmount || null,
            discountPercentage: initialValues.discountPercentage || null,
        },
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            const normalizedValues = {
                ...values,
                discountAmount: values.discountAmount || null,
                discountPercentage: values.discountPercentage || null,
            };
            try {
                setSubmitting(true);
                if (initialValues.id) {
                    await DealerCouponsService.put({ ...normalizedValues, id: initialValues.id });
                    openSnackbar("success", "Coupon updated successfully");
                } else {
                    await DealerCouponsService.post(normalizedValues);
                    openSnackbar("success", "Coupon created successfully");
                }
                if (isMounted.current) {
                    onSuccess();
                    resetForm();
                }
            } catch (error) {
                console.error("Error saving coupon:", error);
                openSnackbar("Failed to save the coupon. Please try again.", "error");
            } finally {
                if (isMounted.current) {
                    setSubmitting(false);
                }
            }
        },
    });

    return (
        <Box component="form" onSubmit={formik.handleSubmit} display="flex" flexDirection="column">
            <Box mb={1}>
                <TextField
                    fullWidth
                    id="couponName"
                    name="couponName"
                    label="Coupon Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.couponName}
                    error={formik.touched.couponName && Boolean(formik.errors.couponName)}
                />
            </Box>
            <Box mb={1}>
                <TextField
                    fullWidth
                    id="couponCode"
                    name="couponCode"
                    label="Coupon Code"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.couponCode}
                    error={formik.touched.couponCode && Boolean(formik.errors.couponCode)}
                />
            </Box>
            <Box mb={1}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={formik.values.inStorePickup}
                            onChange={formik.handleChange}
                            name="inStorePickup"
                            color="primary"
                        />
                    }
                    label="In-Store Pick-Up"
                />
            </Box>
            <Box mb={1}>
                <FormControl fullWidth>
                    <InputLabel>Coupon Type</InputLabel>
                    <Select
                        id="couponType"
                        name="couponType"
                        value={formik.values.couponType}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.couponType && Boolean(formik.errors.couponType)}
                    >
                        <MenuItem value="fixed_price">Fixed Price Coupon</MenuItem>
                        <MenuItem value="percentage">Percentage Coupon</MenuItem>
                        <MenuItem value="free_shipping">Free Shipping Coupon</MenuItem>
                        <MenuItem value="free_shipping_discount">
                            Free Shipping & Discount Percentage
                        </MenuItem>
                    </Select>
                    {formik.touched.couponType && formik.errors.couponType && (
                        <Typography color="error" variant="body2">
                            {formik.errors.couponType}
                        </Typography>
                    )}
                </FormControl>
            </Box>

            {/* Conditionally Rendered Fields */}
            {formik.values.couponType === "fixed_price" && (
                <Box mb={1}>
                    <TextField
                        fullWidth
                        id="discountAmount"
                        name="discountAmount"
                        label="Discount Amount"
                        type="number"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.discountAmount || ""}
                        error={
                            formik.touched.discountAmount && Boolean(formik.errors.discountAmount)
                        }
                    />
                </Box>
            )}
            {(formik.values.couponType === "percentage" ||
                formik.values.couponType === "free_shipping_discount") && (
                <Box mb={1}>
                    <TextField
                        fullWidth
                        id="discountPercentage"
                        name="discountPercentage"
                        label="Discount Percentage"
                        type="number"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.discountPercentage || ""}
                        error={
                            formik.touched.discountPercentage &&
                            Boolean(formik.errors.discountPercentage)
                        }
                    />
                </Box>
            )}

            {/* Date Pickers */}
            <Box mb={1}>
                <CalendarPicker
                    fullWidth
                    label="Start Date"
                    date={formik.values.couponStartDate}
                    setDate={(date) => formik.setFieldValue("couponStartDate", date)}
                />
                {formik.touched.couponStartDate && formik.errors.couponStartDate && (
                    <Typography color="error" variant="body2">
                        {formik.errors.couponStartDate}
                    </Typography>
                )}
            </Box>
            <Box mb={1}>
                <CalendarPicker
                    fullWidth
                    label="End Date"
                    date={formik.values.couponEndDate}
                    setDate={(date) => formik.setFieldValue("couponEndDate", date)}
                />
                {formik.touched.couponEndDate && formik.errors.couponEndDate && (
                    <Typography color="error" variant="body2">
                        {formik.errors.couponEndDate}
                    </Typography>
                )}
            </Box>
            <Box mb={1}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={formik.values.oneCouponPerCustomer}
                            onChange={formik.handleChange}
                            name="oneCouponPerCustomer"
                            color="primary"
                        />
                    }
                    label="One Coupon Per Customer"
                />
            </Box>
            <Box mb={1}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={formik.values.couponActive}
                            onChange={formik.handleChange}
                            name="couponActive"
                            color="primary"
                        />
                    }
                    label="Coupon Active"
                />
            </Box>
            <Box mb={1}>
                <TextField
                    fullWidth
                    id="utmCode"
                    name="utmCode"
                    label="UTM Code"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.utmCode}
                />
            </Box>
            <Box textAlign="center" mt={2}>
                <Button type="submit" color="rose" disabled={formik.isSubmitting}>
                    {initialValues.id ? "Update" : "Create"} Coupon
                </Button>
            </Box>
        </Box>
    );
}
